// Types
import { IChartRawData } from "../components/DataChart"
import { themeData as staticData } from "../data/themeData"

const getName = (subject: string) => {
  switch (subject) {
    case "1-interactionPatterns":
      return "Interaction Patterns"
    case "2-components":
      return "Components"
    case "3-pageTemplates":
      return "Page Templates"
    case "4-frontendCode":
      return "Frontend Code"
    case "5-guidelines":
      return "Guidelines"

    default:
      return "Chart Item"
  }
}

const processChartData = (chartData: IChartRawData) => {
  const treeData = []

  Object.keys(chartData).forEach(id => {
    const name = getName(id)
    const themeData = { name, id, children: [] }

    Object.keys(chartData[id]).forEach(subject => {
      const getDataSubject = staticData[id].subjects.find(
        dataSubject => dataSubject.name === subject
      )

      if (getDataSubject) {
        themeData.children.push({
          name: getDataSubject.title,
          count: chartData[id][subject],
        })
      }
    })

    treeData.push(themeData)
  })

  return treeData
}

export default processChartData
