import { is } from "@amcharts/amcharts4/.internal/themes/ITheme"
import { color as amcColor } from "@amcharts/amcharts4/.internal/core/utils/Color"
import color from "../styles/colors"

const customChartTheme = object => {
  if (is(object as any, "ColorSet")) {
    object.list = [
      amcColor(color.themeBlue),
      amcColor(color.themePink),
      amcColor(color.themeOrange),
      amcColor(color.themeCyan),
      amcColor(color.themePurple),
    ]
    object.minLightness = 0.2
    object.maxLightness = 0.7
    object.reuse = true
  }
  if (is(object as any, "ResizeButton")) {
    object.background.cornerRadiusTopLeft = 20
    object.background.cornerRadiusTopRight = 20
    object.background.cornerRadiusBottomLeft = 20
    object.background.cornerRadiusBottomRight = 20
  }
  if (is(object as any, "Tooltip")) {
    object.animationDuration = 800
  }
}

export default customChartTheme
