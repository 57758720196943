import React, { useEffect } from "react"
import styled from "styled-components"
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"

// Utils
import customChartTheme from "../utils/customChartTheme"
import processChartData from "../utils/processChartData"

// Images
import * as interactionPatternsSVG from "../images/chart-icons/1-interactionPatterns.svg"
import * as componentsSVG from "../images/chart-icons/2-components.svg"
import * as pageTemplatesSVG from "../images/chart-icons/3-pageTemplates.svg"
import * as frontendCodeSVG from "../images/chart-icons/4-frontendCode.svg"
import * as guidelinesSVG from "../images/chart-icons/5-guidelines.svg"

// Styling
import colors from "../styles/colors"

export interface IChartRawData {
  [key: string]: {
    [key: string]: number
  }
}

interface IProps {
  data: IChartRawData
}

const DataChart: React.FC<IProps> = ({ data }) => {
  useEffect(() => {
    am4core.useTheme(customChartTheme)
    am4core.useTheme(am4themes_animated)

    // Create chart
    const chart = am4core.create("chartdiv", am4charts.TreeMap)
    chart.hiddenState.properties.opacity = 0 // this makes initial fade in effect

    // Only one level visible initially
    chart.maxLevels = 1

    // Remove default sorting
    chart.sorting = "none"

    // Define data fields
    chart.dataFields.value = "count"
    chart.dataFields.name = "name"
    chart.dataFields.id = "id"
    chart.dataFields.children = "children"

    /* Add a lagend */
    chart.legend = new am4charts.Legend()
    chart.legend.marginTop = 10
    chart.legend.marginBottom = 40

    /* Remove square from marker template */
    const marker = chart.legend.markers.template
    marker.width = 40
    marker.height = 40
    marker.marginTop = 12
    marker.marginRight = 8
    marker.marginLeft = 8
    marker.align = "left"

    /* Add custom Icon to legent marker */
    const legendIcon = marker.createChild(am4core.Image)

    legendIcon.width = am4core.percent(80)
    legendIcon.height = am4core.percent(80)
    legendIcon.verticalCenter = "top"
    legendIcon.horizontalCenter = "left"
    legendIcon.align = "center"
    legendIcon.marginTop = 100
    legendIcon.paddingTop = 6

    legendIcon.adapter.add("href", (_, target) => {
      const dataItem = target.parent.dataItem
      const name = (dataItem as any).name

      switch (name) {
        case "Interaction Patterns":
          return interactionPatternsSVG
        case "Components":
          return componentsSVG
        case "Page Templates":
          return pageTemplatesSVG
        case "Frontend Code":
          return frontendCodeSVG
        case "Guidelines":
        default:
          return guidelinesSVG
      }
    })

    // level 0 series template
    const level0SeriesTemplate = chart.seriesTemplates.create("0")
    level0SeriesTemplate.strokeWidth = 15

    // by default only current level series bullets are visible, but as we need brand bullets to be visible all the time, we modify it's hidden state
    level0SeriesTemplate.bulletsContainer.hiddenState.properties.opacity = 0
    level0SeriesTemplate.bulletsContainer.hiddenState.properties.visible = true

    const level0ColumnTemplate = level0SeriesTemplate.columns.template.column
    level0ColumnTemplate.cornerRadius(20, 20, 20, 20)

    level0ColumnTemplate.stroke = am4core.color(colors.pantyBrownLight)

    // Create hover state
    const columnTemplate = level0SeriesTemplate.columns.template
    const hoverState = columnTemplate.states.create("hover")

    // Add darken hover state
    hoverState.adapter.add("fill", fill => {
      if (fill instanceof am4core.Color) {
        return am4core.color(am4core.colors.brighten(fill.rgb, -0.2))
      }
      return fill
    })

    // Add Icon Image to the column
    const image = columnTemplate.createChild(am4core.Image)
    image.opacity = 0.8
    image.align = "center"
    image.valign = "middle"
    image.width = am4core.percent(70)
    image.height = am4core.percent(70)

    // add adapter for href to load correct image
    image.adapter.add("href", (_, target) => {
      const dataItem = target.parent.dataItem

      if (dataItem) {
        const id = (dataItem as any).treeMapDataItem.id

        switch (id) {
          case "1-interactionPatterns":
            return interactionPatternsSVG
          case "2-components":
            return componentsSVG
          case "3-pageTemplates":
            return pageTemplatesSVG
          case "4-frontendCode":
            return frontendCodeSVG
          case "5-guidelines":
            return guidelinesSVG

          default:
            return guidelinesSVG
        }
      }
    })

    // Level1 series template
    const level1SeriesTemplate = chart.seriesTemplates.create("1")
    const level1Column = level1SeriesTemplate.columns.template
    level1Column.column.cornerRadius(20, 20, 20, 20)
    level1Column.fillOpacity = 1
    level1Column.stroke = am4core.color("#fff")
    level1Column.strokeWidth = 15
    level1Column.strokeOpacity = 1

    // Add titles to the Level1 series template
    const bullet1 = level1SeriesTemplate.bullets.push(
      new am4charts.LabelBullet()
    )
    bullet1.locationX = 0.5
    bullet1.locationY = 0.5
    bullet1.label.text = "{name}"
    bullet1.label.fontWeight = "700"
    bullet1.label.fill = am4core.color("#ffffff")

    // Add Data to the chart
    chart.data = processChartData(data)

    return () => {
      if (chart) {
        chart.dispose()
      }
    }
  }, [data])

  return (
    <ChartContainer>
      <Chart id="chartdiv" />
    </ChartContainer>
  )
}

const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% + 40px);
  position: relative;
  left: -20px;
`

const Chart = styled.div`
  width: 100%;
  height: 800px;
`

export default DataChart
