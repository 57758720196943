import React, { useEffect, useState } from "react"

// Firebase
import { db } from "../config/firebase"

// Styling
import mq from "../styles/breakpoints"

// Components
import SEO from "../components/Seo"
import DataChart from "../components/DataChart"
import Intro from "../components/Intro"
import styled from "styled-components"
import Footer from "../components/Footer"

const ThankYouPage = () => {
  const [chartData, setChartData] = useState({})

  useEffect(() => {
    let themesData = {}

    db.collection("themes")
      .get()
      .then(doc => {
        doc.forEach(item => {
          const value = item.data()
          const keyName = item.ref.id

          themesData = {
            ...themesData,
            [keyName]: value,
          }
        })

        setChartData({
          ...themesData,
        })
      })
  }, [])

  return (
    <Container>
      <ContentWrapper>
        <SEO title="Results" />
        <Intro title="The most valuable topics for a governmental design system." />
        <DataChart data={chartData} />
      </ContentWrapper>
      <Footer extended={true} />
    </Container>
  )
}

const Container = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
`

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  padding: 40px 20px;

  ${mq.from.breakpoint.M`
  padding: 80px 20px ;
  `};
`

export default ThankYouPage
